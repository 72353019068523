@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed|Roboto:300|Yanone+Kaffeesatz:300&display=swap');
/* You can add global styles to this file, and also import other style files */

html,
button,
input,
optgroup,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}

.drag-target +div{
  top: 20vh!important;
}

button,
a {
  font-weight: bold;
}

.institucional .spinner {
  margin: 40vh auto;
}

.catalog .spinner {
  margin: 10px auto;
}

  #botbutton_interno{
    position: fixed;
    right: 15px;
    top: 88vh;
    z-index: 999999;
  }

  #botbutton_home{
    position: fixed;
    left: 15px;
    top: 88vh;
    z-index: 999999;
  }

.chatbot{
  background-color: #efefef!important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Barlow Condensed', sans-serif;
}

.section h5,
.section h4,
.section p,
.section i {
  color: #FFF;
}

.toast.full {
  height: 105vh;
  font-size: 36px;
  text-align: center;
  width: 100vw;
  position:fixed;
  top:-5vh;
  left:0;
}


nav {
  width: 100vw !important;
  position: fixed;
}

h6,
p,
span {
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Yanone Kaffeesatz', sans-serif!important; */
}

.outlet-section {
  padding-bottom: 20px;
}

.sidenav li>a {
  height: 35px !important;
}

.instrucoes{
  margin-top: -5vh
}

.instrucoes ul {
  list-style: none !important;
  /* Remove default bullets */
}

.instrucoes ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: white;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}

#acessar-button {
  margin-top: 40px;
}

#messagebutton {
  top: 90vh;
  right: 20px;
  position: fixed;
  z-index: 998;
}


#messagebox {
  height: 53vh;
  width: 300px;
  top: 43vh;
  right: 20px;
  position: fixed;
  z-index: 999;
}

#conecttit,
#servtit,
#quemtit,
#quemtext,
.service a,
.contato a {
  color: #FFF;
}

.justify {
  text-align: justify;
}

.start {
  padding-top: 24px;
  height: 100vh;
}


#messagepanel {
  overflow-y: auto;
  height: 87%;
}

#messagebox i {
  font-size: 18px;
  margin: 2px;
}

#scanner-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
}

#scanner-container video,
#scanner-container .drawingBuffer {
  margin-top: 90px;
}

#scanner-container i {
  position: absolute;
  top: 15px;
  right: 5px;
}

.storeitem {
  max-height: 160px;
  margin-bottom: 7px;
  overflow: hidden;
}

.storeitem img {
  max-height: 100%;
}

.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.avatar .circle {
  position: absolute;
  width: 42px;
  height: 42px;
  overflow: hidden;
  left: 15px;
  display: inline-block;
  vertical-align: middle;
}

.avatar {
  min-height: 84px;
  padding-left: 72px !important;
  position: relative;
  padding-top: 35px !important;
}

.avatar p {
  line-height: 1.7rem;
}

#wallet.wallet {
  width: 223px;
  z-index: 1003;
  position: fixed !important;
  top: 100px;
  height: 300px;
  left: 10px;
}

#logo-container img {
  height: 8vh;
}

#nav-deptos {
  z-index: 1004 !important;
}

#formContainer {
  overflow-x: hidden !important;
}

footer.page-footer {
  padding-left: 5vw;
  padding-right: 5vw;
}

.indicator-item{
  background-color: rgba(0,0,0,0.4)!important;
}
.indicator-item.active{
  background-color: rgba(0,0,0,1)!important;
}

.cards {
  overflow: hidden;
  margin-top: 10px;
}

.cards h5,
.cards p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.radio_img~label img {
  max-height: 150px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.radio_img {
  display: none !important;
}

.radio_img:checked~label img,
.radio_img:checked~label div {
  border: 4px solid orange;
}

.radio_img[type="radio"]+label {
  position: initial !important;
  padding-left: initial !important;
  cursor: pointer;
  display: initial !important;
  height: initial !important;
  line-height: initial !important;
}

.step-title {
  color: #EFEFEF !important;
  overflow: visible !important;
}

.step-title::after {
  color: #EFEFEF !important;
}

ul.stepper {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.next-step {
  margin-left: 90px !important;
}

.btn-floating.small {
  width: 30px;
}

.btn-floating.small i {
  line-height: 33px !important;
}

.collapsible-body.extrato {
  padding: 10px !important;
}

.collapsible-body.extrato .tabs {
  height: 40px !important;
}

.confirmpanel {
  top: 30vh !important;
  height: 70vh !important;
  max-height: initial !important;
}

.container.webstore {
  width: 80% !important;
  margin-right: 5vw;
}

.webstore.modal {
  width: 50vw !important;
  margin-left: 20vw !important;
  max-height: 86% !important;
  overflow: visible !important;
}

.suffix{
  position: absolute;
    right: 17px;
    top: 10px;
    padding-left: 5px;
}

.subcategorias {
  z-index: 1005;
  left: 150px !important;
}

.subcategorias li {
  overflow: hidden;
}

.btndepto {
  max-height: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  font-size: 12px;
  margin: 2px;
}

.pagination li a {
  color: #FFF !important;
}

.pagination {
  margin-top: 10px;
}

.thumb {
  height: 30px;
}

.topbordered {
  border-top: 1px solid white;
  padding-top: 10px;
}

.modal.open {
  z-index: 9999 !important;
}

.voucher h5 {
  font-size: 1.3rem;
}

.voucher h6 {
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.voucher .chip {
  min-width: 60px;
  height: 25px;
  font-size: 11px;
}

.voucher .btn-floating {
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.voucher-overlay {
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(96, 96, 96, 1) 0%, rgba(96, 96, 96, 0.9) 38%, rgba(96, 96, 96, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(96, 96, 96, 1) 0%, rgba(96, 96, 96, 0.9) 38%, rgba(96, 96, 96, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(96, 96, 96, 1) 0%, rgba(96, 96, 96, 0.9) 38%, rgba(96, 96, 96, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#606c88', endColorstr='#4d3f4c6b', GradientType=0);
  /* IE6-9 */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px;
}

.voucher:hover .voucher-overlay {
  opacity: 1;
  transition: 1s;
  left: 0;
}

.voucher {
  transition: 1s;
  margin-left: 3px !important;
  margin-right: 3px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  overflow: hidden;
}

.voucher:hover {
  padding: 0 !important;
}

.voucher.m4 {
  height: 180px !important;
  width: 180px !important;
}

.mobile .tabs {
  height: 88px !important;
  background: none !important;
}

/* .tabs .tab {
    
  } */

#loginform {
  margin-top: 5vh !important;
}

.input-field label {
  color: #FFF;
  line-height: initial !important;
  font-style: normal !important;
}

label.black-text {
  color: #757575 !important;
}

nav ul li {
  padding: 0px !important;
}

canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

video {
  width: 100%;
}

.container:not(.login-container, .section-container) {
  min-height: 70vh;

}

.container.login-container,
.container.section-container {
  height: 55vh !important;
  max-height: 370px;
}

.favorite {
  width: 92px;
  height: 92px;
  margin: 2px;
  padding: 5px;
  cursor: pointer;
}

#bank-favorites {
  margin-top: -20px;
  margin-bottom: 20px;
}

.favorite h4,
.favorite h3 {
  margin: 9px 0px 0px 0px !important;
  text-transform: lowercase;
}

.favorite p {
  margin: 5px 0px 0px 0px !important;
  text-transform: lowercase;
}

.favorite h4:first-letter,
.favorite h3:first-letter {
  text-transform: uppercase
}

.upper {
  text-transform: uppercase
}

.btn {
  margin: 5px;
}

.wallet .row {
  margin-bottom: 5px !important;
}

.wallet h1 {
  margin: 0 !important;
}

/*css passwordstrentgh*/

.bar {
  position: relative;
  display: block;
  width: 100%;
  /**/
  /*.bar:before{*/
  /*    content: '';*/
  /*    position: absolute;*/
  height: 4px;
  width: 0;
  border: 1px;
  background-color: #9b59b6;
  transition: all .4s;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  margin-top: 15px;
}

.weak {
  width: 33.3% !important;
  background-color: #e74c3c;
}

.medium {
  width: 66.6% !important;
  background-color: #e67e22;
}

.strong {
  width: 100% !important;
  background-color: #2ecc71;
}

.bold{
  font-weight: 800;
}

input:focus~.bar,
input:valid~.bar {
  width: 100%;
}

/*fim passwordstrength*/

.toppad {
  height: 60px;
}

nav {
  position: fixed;
  z-index: 999;
}

i.small {
  font-size: 2.4rem;
}

.btn.small {
  max-height: 30px !important;
  height: 30px !important;
  line-height: 32px !important;
}

nav i.large {
  font-size: 2.4rem;
}

nav.main,
nav.main ul:not(#nav-mobile),
nav.main ul:not(#nav-mobile) li,
nav.main ul:not(#nav-mobile) li a {
  height: 80px;
  border-radius: 0 0 20px 20px;
}

#demoDropdown {
  height: 355px !important;
  width: 150px !important;
  margin-top: 50px;
  margin-right: 40px;
  overflow: hidden;
  line-height: 20px
}

#demoDropdown li a,
#demoDropdown li {
  height: 50px !important;
}

p.tiny,
span.tiny,
a.tiny {
  font-size: 0.8vw;
  max-height: 20px;
  line-height: 1rem;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -5px !important;
}

.collection {
  border: none;
}

.collection-header {
  margin-top: 10px;
}

.bold {
  font-weight: bold !important;
}

.small-text {
  font-size: 0.9em;
  line-height: 1.5em;
}

.link {
  cursor: pointer;
}

.link_ {
  line-height: 100px;
  cursor: pointer;
}

.logged .link_ {
  max-width: 10vw;
}

.shadow-icon {
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
}

.top-15 {
  margin-top: 15vh;
}

.top-10 {
  margin-top: 10vh;
}

.top-5 {
  margin-top: 5vh;
}

.padding-5 {
  padding-top: 5vh;
}

.brand-logo {
  margin-top: 8px;
}

.brand-logo-p {
  max-height: 9vh;
  margin: 5px;
}

.brand-logo img {
  height: 60px;
}

.parallax.img {
  background-size: cover !important;
  background-position: center center !important;
  filter: brightness(60%);

}

.parallax-container .parallax {
  z-index: 0 !important;
}

.parallax-container .section {
  z-index: 2 !important;
}

.darken {
  background-color: rgba(0, 0, 0, 0.4);
}

.centered {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.padded {
  padding: 20px 10px 20px 10px !important;
}

.bordered {
  border: 2px solid white;
}

i.menu,
i.action {
  cursor: pointer;
}

body {
  /* background-color: #9b59b6!important; */
}

nav,
.btn,
.bottom-nav {
  /* background-color: #6f26a6!important; */
  box-shadow: initial !important;
}

.btn,
footer {
  /* background-color: #6f26a6!important; */
}

#register {
  padding-top: 100px;
}

.nav-wrapper.container {
  min-height: 100px !important;
}

.select-dropdown li.disabled>span {
  color: rgba(0, 0, 0, 0.3);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  -webkit-filter: blur(1.2px);
  opacity: 0.3;
}

.caret {
  color: #999 !important;
}

.video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}

p {
  line-height: 2rem;
}

.button-collapse {
  color: #FFF;
  margin: 0 !important;
  margin-top: 20px !important
}

.parallax-container {
  min-height: 46vh;
  line-height: 0;
  height: auto;
  color: rgba(255, 255, 255, .9);
  border-radius: 0 0 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.14);
}

.parallax-container .section {
  width: 100%;
}


@media only screen and (max-width: 992px) {
  .parallax-container:not(#index-banner) {
    height: 40vh;
  }

  .toppad {
    height: 58px;
  }

  #wallet {
    display: none;
  }

  .wallet {
    background-color: #ddd;
  }

  .wallet .row {
    margin-bottom: 0px !important;
  }

  #index-banner .section {
    top: 0;
  }

  .parallax-container .section {
    position: absolute;
  }

  #index-banner .section {
    top: 10%;
  }

  .icon-block {
    padding: 0 15px;
  }

  .icon-block .material-icons {
    font-size: inherit;
  }

  footer.page-footer {
    margin: 0;
  }

  #loginform {
    margin-top: 16px !important;
  }

  #modal-popup{
    width: 100%!important;
  }

  .modal-content img{
    width: 100%!important;
    height: auto!important;
}
}

@media only screen and (max-width: 600px) {

  p.tiny,
  span.tiny,
  a.tiny {
    font-size: 12px;
    max-height: 20px;
    line-height: 1rem;
    padding: 0 !important;
    margin: 0 !important;
    margin-top: -5px !important;
  }

  .voucher h5 {
    font-size: 1.0rem !important;
  }

  .voucher.m4 {
    height: 40vw !important;
    width: 40vw !important;
  }

  .voucher .chip {
    min-width: 50px;
    height: 25px;
    font-size: 10px;
  }

  .container {
    /* min-height: 120vh; */
  }

  .brand-logo img {
    height: 100px;
  }

  h1.header {
    font-size: 240%;
  }

  .webstore.modal {
    width: 90vw !important;
    margin-left: 5vw !important;
    margin-right: 5vw !important;
    max-height: 90vh !important;
    height: 85vh !important;
  }

  #botbutton_home{
    position: absolute;
    top: 13vh;
    z-index: 999999;
    right: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .toast.full {
    height: 460px;
    font-size: 36px;
    text-align: center;
    width: 280px;
    position:relative;
  }

  .toast.overcomb {
    top: -18vh !important;
  }

  .confirmpanel {
    width: 40vw !important;
  }

  .brand-logo {
    margin-top: 21px
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
  }

  .parallax-container {
    min-height: 53vh;
  }

  .container {
    max-width: 98%!important;
  }

  nav.main,
  nav.main ul:not(#nav-mobile),
  nav.main ul:not(#nav-mobile) li,
  nav.main ul:not(#nav-mobile) li a {
    height: 12vh!important;
  }
}

@media only screen and (max-width: 992px) {

  nav .sidenav-trigger {
    margin: 18px -10px;
  }

  nav .sidenav-trigger i {
    font-size: 25px;
  }

  .sidenav {
    width: 200px !Important;
  }

  .sidenav li>a {
    padding: 0 !important;
    text-transform: uppercase;
    font-size: 13px;
  }

  .sidenav li>a>i {
    margin-right: 15px !important;
  }

  #logo-container {
    float: right !important;
    padding-top: 5px;
  }

  #logo-container img {
    margin: 8px auto !important;
  }

  .container.webstore {
    width: 90% !important;
  }

  h3.upper {
    font-size: 230%;
  }

  #download-button {
    /* position: fixed;
        top: 85vh;
        left: 76vw; */
  }
}